<template>
  <div v-if="show" class="mockup-container">
    <div id="mockup" ref="mockup" class="mockup">
      <div class="iframe-container">
        <transition name="mockup">
          <video
            v-if="showVideo"
            ref="videoPlayer"
            v-lazy-load :data-src="fileUrl"
            :type="mimeType"
            title="resimo video"
            class="mockup-yt"
            autoplay
            playsinline
            preload="metadata"
            muted
          >
            Your browser does not support the video tag.
          </video>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from '@/utils/mobile-device-detect.js'
import AOS from 'aos'
import { isElementInViewport } from '@/utils/is-element-in-viewport'

export default {
  name: 'AppAnimatedMockup',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    fileUrl: {
      type: String,
      default: '',
    },
    mimeType: {
      type: String,
      default: 'video/mp4',
    },
  },
  data() {
    return {
      constrain: 800,
      mockup: null,
      mouseMoveEvent: null,
      showVideo: false,
      isInViewport: false,
      videoPlayer: null,
    }
  },
  watch: {
    isInViewport(v) {
      if (v && this.videoPlayer?.paused) this.videoPlayer?.play()
      if (!v && !this.videoPlayer?.paused) this.videoPlayer?.pause()
      if (v && !isMobile) {
        this.mouseMoveEvent = window.addEventListener('mousemove', this.addMouseMoveEvent)
      } else {
        window.removeEventListener('mousemove', this.addMouseMoveEvent)
        this.resetTransform(this.mockup)
        this.mouseMoveEvent = null
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    this.showVideo = false
  },
  mounted() {
    AOS.refresh()
    this.$nextTick(() => {
      this.mockup = this.$refs?.mockup

      if (this.mockup) {
        this.isInViewport = isElementInViewport(this.mockup)
      }

      window.addEventListener('scroll', this.handleScroll)

      setTimeout(() => {
        this.showVideo = true
        this.$nextTick(() => {
          this.videoPlayer = this.$refs?.videoPlayer
        })
      }, 2000)

      setTimeout(() => {
        AOS.refresh()
        if (this.videoPlayer) this.videoPlayer.play()
      }, 2400)
    })
  },
  methods: {
    transforms(x, y, el) {
      const box = el.getBoundingClientRect()
      const calcX = -(y - box.y - box.height / 4) / this.constrain
      const calcY = (x - box.x - box.width / 4) / this.constrain

      return `perspective(100px) rotateX(${calcX}deg) rotateY(${calcY}deg)`
    },
    transformElement(el, xyEl) {
      el.style.transform = this.transforms.apply(null, xyEl)
    },
    resetTransform(el) {
      el.style.transform = `perspective(0) rotateX(0) rotateY(0)`
    },
    addMouseMoveEvent(e) {
      const xy = [e.clientX, e.clientY]
      const position = xy.concat([this.mockup])

      window.requestAnimationFrame(() => {
        this.transformElement(this.mockup, position)
      })
    },
    handleScroll() {
      this.isInViewport = isElementInViewport(this.mockup)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/AppAnimatedMockup';
</style>
